@import '../var';

.textarea{
  resize: none;
  width: 100% !important;
  color: #3A424F;
  font-size: 14px;
  font-weight: 300;
  height: 100%;
  max-height: 100%;
  min-height: 100px !important;
  background-color: $bgcontrols;
  border-radius: 0;
  padding: 8px;
  transition: $transition;
  border: 1px solid $bgcontrols;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);

  &:focus{
    background: $bgcontrols;
    font-size: 14px;
    box-shadow: inset 0px -3px 0 0 $main;
  }
}

.note-editor{
  &.note-frame{
    margin: 0 50px;
  }

  .note-btn-group{
    &.note-color,
    &.note-fontname{
      display: none;
    }
  }
}

.mce-btn,
.mce-panel{
  background-color: inherit !important;
}

.mce-tinymce{
  box-shadow: 0 0 0 !important;
}

.example-content-description{
  opacity: 0;
}
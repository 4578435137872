@import '../var';

.material-button{
  background: $bgcontrols;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: $main;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  margin-left: 10px;

  &--danger{
    color: $danger;
  }
}

.block-title{

  .button{
    display: none;

    float: right;
    width: auto;
    font-size: 13px;
    border-radius: 2px;
    border: 1px solid #fff;
    height: 35px;
    line-height: 34px;
    text-transform: uppercase;
    font-weight: 100;
    padding: 0 25px;
    margin-right: 7px;
    
    &:hover{
      background-color: rgba(255,255,255,0.1);
      color: #fff;
    }

    &--active{
      display: block;
    }
  }
}
@import '../var';

.select{
  height: 30px;
  line-height: 29px;
  // border: 1px solid #BFC5D1;
  border-radius: 2px;
  cursor: pointer;
  padding: 0 25px 0 15px;
  position: relative;
  font-weight: 300;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);

  &-inner{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  .material-icons{
    position: absolute;
    top: 8px;
    right: 4px;
    color: #3A424F;
    font-size: 18px;
  }

  .dropdown {
    right: -1px;
    top: 29px;
    width: calc(100% + 2px);
    border: 1px solid #BFC5D1;
    border-top: 0;
    transform: translateX(0) translateY(-20px);
    font-size: 12px;

    &:after{
      display: none;
    }

    &-item{
      height: 30px;
      color: #3A424F !important;

      span{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-left: 0;
      }

      &:hover{
        background: #dee2ed;
      }
    }
  }

  &_pages{
    width: 80px;
  }

  &_item{
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: #f5f6fa;
    border: 1px solid $bgcontrols;

    &.vis{
      border-color: #80a8ff;
    }

    .material-icons{
      color: #80a8ff;
    }
  }

  &_empty{
    background: $bgcontrols;
    .select-inner{
      color: #BFC5D1;
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.select2-container{
  max-width: 100%;
}

.select2-container--default{
  display: block;
  //width: 100% !important;
  max-width: 320px !important;
  margin: 0;
  background: #f5f6fa;

  .select2-selection--single{
    background: #f5f6fa;
    border: 0;
  }


}


.select2-drop{
  background-color: #f5f6fa !important;
}

.field-tours-cities,
.field-tours-categories{
  .help-block{
    margin: 0;
  }
}